<template>
  <b-modal
    id="tab"
    ok-only
    ok-title="Save"
    centered
    title="Create Country"
    v-model="isOpen"
    @ok="save"
  >
    <b-form-group label="Name" label-for="h-name" label-cols-md="4">
      <b-form-input
        id="h-wed"
        type="text"
        v-model="country.name"
        placeholder="Name"
      />
    </b-form-group>
    <b-form-group label="Code" label-for="h-code" label-cols-md="4">
      <b-form-input
        id="h-code"
        type="text"
        v-model="country.code"
        placeholder="Code"
      />
    </b-form-group>
  </b-modal>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
      isUpdate: false,
      country: {},
    };
  },
  methods: {
    openModal(editCountry) {
      if (editCountry) {
        this.country = editCountry;
        this.isUpdate = true;
      } else {
        this.country = {};
        this.isUpdate = false;
      }
      this.isOpen = true;
    },
    save() {
      if (this.isUpdate) {
        this.$emit("update", this.country);
        this.isUpdate = false;
      } else {
        this.$emit("create", this.country);
      }
      this.country = {};
    },
  },
};
</script>
