<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Country" class="mb-3" />
      </b-col>
    </b-row>
    <b-media>
      <section class="py-3">
        <b-row>
          <b-col cols="10">
            <h2 class="mb-2">Country Management</h2>
          </b-col>
          <b-col cols="2">
            <div class="text-center">
              <b-button @click="showForm" variant="primary">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span>Add Country</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </section>
    </b-media>
    <b-card>
      <country-table @edit="edit" @getList="getList" :countries="countries" />
    </b-card>
    <country-form-modal
      @update="update"
      @create="create"
      ref="countryFormModal"
    />
  </div>
</template>

<script>
import service from "../service";
import CountryFormModal from "../views/_components/CountryFormModal.vue";
import CountryTable from "../views/_components/CountryTable.vue";

export default {
  components: {
    CountryFormModal,
    CountryTable,
  },
  data() {
    return {
      current_page: 1,
      total_items: 0,
      items_perpage: 50,
      countries: [],
      editCountry: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      let response = await service.getList({
        page: this.current_page,
        limit: this.items_perpage,
      });
      if (response.data.type === "DATA") {
        if (this.countries.length > 0) {
          this.$store.dispatch("country/setCountry");
        }
        this.countries = response.data.data.list;
        this.total_items = response.data.data.total;
      }
    },
    showForm(type) {
      if (type != "update") {
        this.editCountry = null;
      }
      this.$refs.countryFormModal.openModal(this.editCountry);
    },
    async create(country) {
      let response = await service.create(country);
      if (response.data.type == "DATA") {
        this.$store.dispatch("pushSuccessNotify", { text: "Successfully!" });
        this.getList();
      }
    },
    edit(countryInfo) {
      this.editCountry = countryInfo;
      this.showForm("update");
    },
    async update(info) {
      await service.update({ data: JSON.stringify(info) });
      this.getList();
    },
  },
};
</script>
